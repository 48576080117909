import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import Typography from '@material-ui/core/Typography'





const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    //maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    fontFamily: 'Arial Black'
  },
  nested: {
    paddingLeft: theme.spacing(4),
    width: '100%',
    fontFamily: 'Montserrat'
  },
  links: {
    color: 'blue',
    textDecoration:'underline',
    whiteSpace: "pre-line"
  },
  fontText: {
    fontFamily: 'Montserrat !important',
    textAlign: 'justify'
  }
}));

export default function NestedList({Question}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  if(!Question) return null
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
    >
      <ListItem button onClick={handleClick} className={classes.root}>
        <ListItemIcon>
          <HelpIcon  />
        </ListItemIcon>
        <ListItemText primary={Question.question} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <QuestionAnswerIcon />
            </ListItemIcon>
            <ListItemText className={classes.fontText} primary={Question.answer} />
          </ListItem>
          {  Question.links.map( link =>{
                    return(
                        <ListItem button component="a" href={link}>
                          {/* <ListItemText className={classes.links} primary={link}/> */}
                          <Typography noWrap className={classes.links}>{link}</Typography>
                        </ListItem>
                      )
                })}
        </List>
      </Collapse>
    </List>
  );
}