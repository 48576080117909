import React from "react"
import {Typography, Grid,Paper} from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles';

import Layout from "../../components/layout"
import { useStaticQuery, graphql } from "gatsby";
import FAQ from "./FAQ"
const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2),
    //marginBottom: theme.spacing(2),
    fontFamily:"Arial Black"
    },
    title:{
      fontFamily:"Arial Black",
      color:"white",
      display:"flex",
      width:"100%",
      alignItems:"start",
      justifyContent: "start",
      backgroundColor:"#ad1d21",
      opacity:"0.85"
    },
    font:{
      fontFamily:"Montserrat"
    },
    FAQ:{
      width:"100%"
    },
    paper:{
      width:"100%",
    }
}));

const Events = () => {
  const classes = useStyles();   
  const data= useStaticQuery(graphql`
  query {
    allRestApiApiFrequentqasShowall {
      edges {
        node {
          data {
            frequentQAs {
              question
              answer
              
              ConsultationType {
                content
              }
              links
            }
          }
        }
      }
    }
  }`)
  //console.log(data.allRestApiApiEventsShowall.edges[0].node.data.events)
  let actualQA =''
  const sortByProperty = function (property, property2) {
    return function (x, y) {
        return ((x[property][property2] === y[property][property2]) ? 0 : ((x[property][property2] > y[property][property2]) ? 1 : -1));
    };
};
data.allRestApiApiFrequentqasShowall.edges[0].node.data.frequentQAs.sort(sortByProperty('ConsultationType', 'content' ))
  return(
    <Layout>
      <Typography className={classes.margin} component="h4" variant="h4">Preguntas Frecuentes</Typography>

      <Grid 
      container
      direction="column"
      justify="start"
      alignItems="start">
      { 
       data.allRestApiApiFrequentqasShowall.edges[0].node.data.frequentQAs.map( (question, index) => {
        return(
            <Grid 
                direction="column"
                item xs={12}
                justify="start"
                
                alignItems="start">
                
                


             {(actualQA!== question.ConsultationType.content)?
             (actualQA=question.ConsultationType.content,
             
                <Grid 
                container
                justify="center"
                className={classes.margin}
                alignItems="center">
              
            <Typography variant="h5" component="h5" className={classes.title}>{actualQA}</Typography>

            </Grid>
            
            ):('')}
            <Paper className={classes.paper}>
                <FAQ Question={question} className={classes.FAQ} />
            </Paper>
          </Grid>
          
          )
          
       }
       )}
      </Grid>
    </Layout>
  )
}
export default Events
